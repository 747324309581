/* eslint-disable */
import _ from "lodash";
export const imgPlaceHolder = (args, size = "200x200") => {
  const img = `http://via.placeholder.com/${size}?text=${args}`;
  return img;
};

export const insertUpdateObjectToList = (list = [], data = {}) => {
  const newList = list.map((item) => {
    if (`${data.id}` !== `${item.id}`) return item;
    return _.assign({}, item, data);
  });

  return newList;
};

export const isNumber = (number) => {
  if (_.isEmpty(`${number}`)) return true;
  // const regexp = /^[0-9]+([,.][0-9]+)?$/g;
  const regexp = /^[0-9.]*$/;
  return regexp.test(number);
};

export const omitEmptyArgs = (args) => {
  const newArgs = {};
  _.forEach(args, (value, key) => {
    if (!_.isEmpty(`${value}`) && !_.isNull(value)) {
      newArgs[key] = value;
      return newArgs;
    }
    return newArgs;
  });

  return newArgs;
};

export const numberWithCommas = (x = 0) => {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const deepCleanObjForNull = (obj) => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line no-alert
    alert(
      'You are using an array on "deepCleanObjForNull". Returning the object without cleaning...'
    );
    return obj;
  }

  const newObj = {};

  for (const key in obj) {
    const val = obj[key];
    if (_.isNil(val)) {
      newObj[key] = "";
      continue; // eslint-disable-line no-continue
    }
    if (val === "null") {
      newObj[key] = "";
      continue; // eslint-disable-line no-continue
    }
    if (typeof val === "number") {
      newObj[key] = val;
      continue; // eslint-disable-line no-continue
    }
    if (typeof val === "object" && !Array.isArray(val)) {
      newObj[key] = deepCleanObjForNull(val);
      continue; // eslint-disable-line no-continue
    }
    if (typeof val === "object" && Array.isArray(val)) {
      newObj[key] = val.map((item) => {
        if (typeof item === "object" && !Array.isArray(item)) {
          return deepCleanObjForNull(item);
        }
        if (_.isNil(item)) {
          return "";
        }
        return item;
      });
      continue; // eslint-disable-line no-continue
    }
    newObj[key] = val;
  }

  return newObj;
};

export const formatedSelectOption = (
  list = [],
  value = "id",
  label = "name",
  isToUpperCase = true
) => {
  const newData = [];
  list.forEach((item) => {
    newData.push({
      value: _.get(item, `${value}`),
      label: isToUpperCase
        ? _.get(item, `attributes.${label}`).toUpperCase()
        : _.get(item, `attributes.${label}`),
      data: { ...item },
    });
  });
  return newData;
};

export const reactSelectCustomStyle = {
  control: (base) => ({
    ...base,
    minHeight: 44,
  }),
  container: (base) => ({
    ...base,
    borderRadius: "7px !important",
  })
  // control: (base, { isDisabled }) => ({
  //   ...base,
  //   backgroundColor: "none",
  //   minHeight: 36,
  //   border: "none",
  //   boxShadow: '0 !important',
  //  '&:hover': {
  //      border: '0 !important'
  //   },
  //   width: "100%",
  // }),
  // container: () => ({
  //   border: "1px solid #d9d9d9 !important",
  //   height: "43px !important",
  //   borderRadius: "7px !important",
  // }),
  // placeholder: (base, { isDisabled }) => ({
  //   color: isDisabled ? "#dedede" : "silver",
  // }),
  // dropdownIndicator: (base) => ({
  //   ...base,
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   cursor: "pointer",
  // }),
  // clearIndicator: (base) => ({
  //   ...base,
  //   paddingTop: 0,
  //   paddingBottom: 0,
  //   cursor: "pointer",
  // }),
  // singleValue: () => ({
  //   color: "#4c6085",
  //   fontWeight: "bold",
  // }),
  // menu: (base) => {

  //   return {
  //   ...base,
  //   zIndex: 1000,
  // }},
  // option: (base) => {
  //   return {
  //   ...base,
  //   zIndex: 1000
  // }},
};

export const reactSelectCustomStyleCms = (data, containerItem) => ({
  control: (base, { isDisabled }) => ({
    ...base,
    backgroundColor: "none",
    backgroundColor: isDisabled ? 'rgba(239, 239, 239, 0.3)' : 'white',
    minHeight: 40,
    border: "none",
    boxShadow: "none",
    width: "100%",
  }),
  container: () => ({
    ...containerItem,
    border: `${containerItem?.border ? containerItem?.border : "1px solid #d9d9d9"}`, // border - temporary fix for border attributes
    minHeight: "43px !important",
    borderRadius: `${containerItem?.borderRadius ? containerItem?.borderRadius : "7px !important"}`, // borderRadius - temporary override borderRadius
    width: "100%",
  }),
  placeholder: (base, { isDisabled }) => ({
    color: isDisabled ? "#dedede" : "silver",
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    color: isDisabled ? '#d9d9d9' : '#7790CB',
    clipPath: "polygon(50% 70%, 30%  35%, 70% 35%)",
    backgroundColor: isDisabled ? '#d9d9d9' : '#7790CB',
    cursor: "pointer",
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    cursor: "pointer",
  }),
  // singleValue: () => ({
  //   color: "#4c6085",
  //   fontWeight: "bold",
  // }),
  menu: (base) => {
    return {
      ...base,
      zIndex: 1000,
    }
  },
  option: (base, act) => {
    return {
      ...base,
      zIndex: 1000,
      color: act.isSelected ? _.get(data, 'primary_color') : '#717171',
      backgroundColor: act.isFocused || act.isSelected ? _.get(data, 'primary_color_light') : 'transparent'
    }
  },
});

export const computeCedulaInterest = (total, interestRate) => {
  const baseInterestRate = interestRate || 0.02;
  const now = new Date();
  const currentMonth = now.getMonth() + 1;

  const monthlyInterestRate = +currentMonth * baseInterestRate;

  if (currentMonth > 2) {
    return {
      total: total * monthlyInterestRate + total,
      interest: total * monthlyInterestRate,
      interest_percentage: monthlyInterestRate * 100,
    };
  }

  return {
    total: total,
    interest: 0,
    interest_percentage: 0,
  };
};

export const handleOpenPgiUrl = (pgiUrl, callback) => {

  const title = "PAYMENT GATEWAY";
  const h = 675;
  const w = 775;
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;

  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  let newWindow = "";

  newWindow = window.open(
    `${pgiUrl}?back=true`,
    title,
    `scrollbars=yes, width=${w / systemZoom}, height=${h /
    systemZoom}, top=${top}, left=${left}`
  );

  var timer = setInterval(function () {
    if (newWindow) {
      if (newWindow.closed) {
        clearInterval(timer);
        if (callback) callback();
      }
    }
  }, 1000);

  if (window.focus && newWindow) newWindow.focus();
};

// export const handleOpenPgiUrl = (pgiUrl, callback) => {
  // const title = "PAYMENT GATEWAY";
  // const h = 675;
  // const w = 775;
  // // Fixes dual-screen position                         Most browsers      Firefox
  // const dualScreenLeft =
  //   window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  // const dualScreenTop =
  //   window.screenTop !== undefined ? window.screenTop : window.screenY;

  // const width =
  //   window.innerWidth ||
  //   document.documentElement.clientWidth ||
  //   window.screen.width;

  // const height =
  //   window.innerHeight ||
  //   document.documentElement.clientHeight ||
  //   document.documentElement.clientHeight ||
  //   window.screen.height;

  // const systemZoom = width / window.screen.availWidth;
  // const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  // const top = (height - h) / 2 / systemZoom + dualScreenTop;
  // let newWindow = "";

  // if (window.screen.width <= 768) {
  // window.location.href = `${window.location.origin}/pgi?url=${pgiUrl}`;
  // } else {
  // newWindow = window.open(
  //   `${pgiUrl}`,
  //   title,
  //   `scrollbars=yes, width=${w / systemZoom}, height=${h /
  //   systemZoom}, top=${top}, left=${left}`
  // );
  // }

  // var timer = setInterval(function () {
  //   if (newWindow) {
  //     if (newWindow.closed) {
  //       clearInterval(timer);
  //       if (callback) callback();
  //     }
  //   }
  // }, 1000);

  // if (window.focus && newWindow) newWindow.focus();
// };

export const handleOpenBillsPayment = (pgiUrl, callback) => {
  const title = "BILLS PAYMENT";
  const h = 675;
  const w = 775;
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  // ? window.innerWidth
  // : document.documentElement.clientWidth
  // ? document.documentElement.clientWidth
  // : screen.width;

  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;
  // ? window.innerHeight
  // : document.documentElement.clientHeight
  // ? document.documentElement.clientHeight
  // : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  let newWindow = "";

  if (window.screen.width <= 768) {
    newWindow = window.open(pgiUrl, "_self");
  } else {
    newWindow = window.open(
      pgiUrl,
      title,
      `scrollbars=yes, width=${w / systemZoom}, height=${h /
      systemZoom}, top=${top}, left=${left}`
    );
  }

  var timer = setInterval(function () {
    if (newWindow.closed) {
      clearInterval(timer);
      if (callback) callback();
    }
  }, 1000);

  if (window.focus && newWindow) newWindow.focus();
};

const isSomeWordExist = (in_what_word, array_of_word_to_check) => {
  return array_of_word_to_check
    .map((item) => in_what_word.indexOf(item) > -1)
    .some((item) => item === true);
};

const formatKeyValue = (key, value) => {
  const isExistInSome = isSomeWordExist(key, [
    "email",
    "password",
    "confirm_password",
    "code",
    "highest_educational_attainment",
    "photo",
    "url",
    "valid_id_to_present",
    "purpose",
  ]);
  if (typeof value === "string" && !isExistInSome) {
    return {
      [key]: value.toUpperCase(),
    };
  }

  return {
    [key]: value,
  };
};

export const payloadToUpperCase = (data) => {
  return Object.entries(data).reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      ...formatKeyValue(currentValue[0], currentValue[1]),
    };
  }, {});
};

export const subYears = (count) => {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  const c = new Date(year - count, month, day);
  return c;
};

export const subDays = (count) => {
  const d = new Date();
  const final = new Date(d.setDate(d.getDate() - count));
  return final;
};

export const truncateString = (string, maxLength = 50) => {
  if (!string) return null;
  if (string.length <= maxLength) return string;
  return `${string.substring(0, maxLength)}...`;
};

const padBounds = (bounds, padding = 0.4) => {
  const x1 = bounds[0];
  const y1 = bounds[1];
  const x2 = bounds[2];
  const y2 = bounds[3];

  // const x_center = (((x1 + x2) - x1) / 2) + x1;
  // const y_center = (((y1 + y2) - y1) / 2) + y1;
  // const w_padded = (x2 - x1) * padding;
  // const h_padded = (y2 - y1) * padding;
  // const size = w_padded > h_padded ? w_padded : h_padded;

  // const new_x1 = Math.floor(x_center - (size / 2));
  // const new_y1 = Math.floor(y_center - (size / 2));
  // const new_x2 = Math.floor(x_center + (size / 2)) - new_x1;
  // const new_y2 = Math.floor(y_center + (size / 2)) - new_y1;

  const new_x1 = Math.floor(x1 - x2 * padding);
  const new_y1 = Math.floor(y1 - y2 * padding);
  const new_x2 = Math.floor(x2 + x2 * padding * 2);
  const new_y2 = Math.floor(y2 + y2 * padding * 2);

  return {
    x: new_x1,
    y: new_y1,
    w: new_x2,
    h: new_y2,
  };
};

export const autoCropFace = async (ucare_url) => {
  try {
    const res = await axios.get(`${ucare_url}detect_faces/`);
    const face = _.get(res, "data.faces.0") || [];
    if (_.isEmpty(face)) {
      alert("Unable to crop automatically. No face detected.");
      return ucare_url;
    }
    const { x, y, w, h } = padBounds(face);
    return `${ucare_url}-/crop/${w}x${h}/${x},${y}/-/preview/`;
  } catch (err) {
    alert("Unable to crop automatically. Crop error!");
    return ucare_url;
  }
};

export const formatNumber = (no) => {
  const firstTwo = (no || '').substring(0, 2);
  if (firstTwo === "09") {
    return no.replace(/^.{2}/g, "639");
  }
  return no;
};

export const getServicesList = () => {
  try {
    const uniqueSet = JSON.parse(localStorage.getItem('services'));
    // const combArr = [].concat(...uniqueSet);
    // const uniqueSet = arr;
    // const newSet = combArr.reduce((i, item) => i.concat(item.code), []);
    return uniqueSet;
  } catch (err) {
    return [];
  }
};

export const getAge = (dateString) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
}


export const getLguName = () => {

  return "MANILA"
}

export const getBPLS = () => {
  // return "BPLS" // new
  return "TBPLS" // old
}

export const ucwords = (str) => {
  let val;
  val = `${str}`.replace(/^(.)|\s+(.)/g, ($1, val) => {
    return $1.toUpperCase();
  });
  return replaceValue(val)
}

export const replaceValue = (str) => {
  let newString = "";
  str.split(" ").map((data) => {
    if (data === "No") {
      data = data + '.';
    }
    if (data === "Of") {
      data = 'of';
    }
    newString = newString + " " + data
  });
  return newString
}

export const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, 100)
}

export const scrollToTop2 = (height) => {
  setTimeout(() => {
    window.scrollTo({
      top: height,
      left: 0,
      behavior: "smooth",
    });
  }, 100)
}

export const numberFormat = (value, decimal_place) => {
  return Number((value === "0" || !value ? "0.00" : value)).toLocaleString(undefined, {
    minimumFractionDigits: decimal_place,
    maximumFractionDigits: decimal_place,
  })
};

export const parseNumber = (num, default_value = false) => {
  const v = parseFloat(`${num}`.replace(/,/g, ''));
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(v)) return typeof default_value !== 'boolean' ? default_value : num;
  return v;
};

export const formatCurrency = (amount, currency = 'PHP') => {
  const num = parseNumber(amount, false);
  if (typeof num !== 'number') return '-';
  return Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(num);
};